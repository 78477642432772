import React, { useState } from "react"

import Img from "gatsby-image/index"

import { ModalGallery } from "../gallery"

import styles from "./interior-card.module.scss"

const InteriorGard = ({ interior }) => {
  const title = interior.name
  const images = (interior.photos || []).map(i => i.fluid)

  const [showModalGallery, setShowModalGallery] = useState(false)

  return (
    <div>
      <div
        role="button"
        tabIndex={0}
        aria-hidden="true"
        className={styles.card}
        onClick={() => setShowModalGallery(true)}
      >
        <Img
          fluid={images[0]}
          objectFit="cover"
          objectPosition="50% 50%"
          className={styles.thumbnail}
          alt={title}
        />
        <h3>{interior.name}</h3>
      </div>

      <ModalGallery
        title={title}
        images={images}
        show={showModalGallery}
        setShow={setShowModalGallery}
        initialSlide={0}
        contentType="інтер'єр"
      />
    </div>
  )
}

export default InteriorGard
