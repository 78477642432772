export const ONE_MINUTES = 60 * 1000
export const ONE_HOURS = 60 * ONE_MINUTES
export const ONE_DAY = 24 * ONE_HOURS

export const diffDays = (from, to) => {
  const firstDate = new Date(from)
  const secondDate = new Date(to)

  return Math.round(Math.abs((firstDate - secondDate) / ONE_DAY))
}

export const daysFrom = from => diffDays(from, Date.now())
