exports.formatPrice = price => {
  price = String(price)

  const lasIndex = price.length - 1

  return price
    .split("")
    .reverse()
    .map((str, i) =>
      i !== 0 && (i + 1) % 3 === 0 && i !== lasIndex ? `,${str}` : str
    )
    .reverse()
    .join("")
}
