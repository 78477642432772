import React from "react"

import Modal from "react-overlays/Modal"

import { Swiper, SwiperSlide } from "swiper/react"
import Img from "gatsby-image/index"

import { getIcon, notifyPhoneClick, sendEventToGA4 } from "../../utils/index"
import { Icon } from "../../components/icon"

import styles from "./modal-gallery.module.scss"
import { useSiteMetadata } from "../../hooks"

function ModalGallery({
  title,
  show,
  setShow,
  images,
  initialSlide,
  contentType,
}) {
  const renderBackdrop = props => <div className="modal-backdrop" {...props} />

  const renderFraction = function (currentClass, totalClass) {
    return `
    <div class="${styles.pagination}">
       <div class="${styles.fraction}">
         <span class="${currentClass}"></span>
         <span style="margin: 0 2px;">&hArr;</span>
         <span class="${totalClass}"></span>
      </div>
    </div>
`
  }

  const {
    contacts: { phone: fullPhone, formattedPhone },
  } = useSiteMetadata()

  const handlePhoneClick = () => {
    sendEventToGA4({
      action: "phone_click",
      category: "phone",
      label: "Modal Gallery Phone",
    })

    notifyPhoneClick({
      category: "Гелерея",
      label: `Фото ${contentType} - знизу`,
    })
  }

  return (
    <Modal
      show={show}
      onHide={() => setShow(false)}
      aria-labelledby="modal-label"
      renderBackdrop={renderBackdrop}
      className={styles.modal}
    >
      <div>
        <div className={styles.header}>
          <h3>{title}</h3>
          <button
            onClick={() => setShow(false)}
            type="button"
            aria-label="закрити"
          >
            <Icon name={"закрити"} icon={getIcon("close")} />
            <span> Закрити</span>
          </button>
        </div>
        <div className={styles.galleryWrapper}>
          {show ? (
            <Swiper
              navigation
              initialSlide={initialSlide}
              pagination={{ type: "fraction", renderFraction }}
              keyboard={{ enabled: true }}
              loop={true}
              zoom={true}
              autoHeight={true}
              lazy={true}
            >
              {images.map((image, i) => (
                <SwiperSlide key={image.src + i}>
                  <Img
                    fluid={image}
                    imgStyle={{ objectFit: "contain" }}
                    className={styles.photo}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          ) : null}
        </div>
        <a
          href={`tel:${fullPhone}`}
          className={styles.phoneLink}
          onClick={handlePhoneClick}
        >
          <Icon name={"зателефонувати"} icon={getIcon("phone link")} />
          <span>{formattedPhone}</span>
        </a>
      </div>
    </Modal>
  )
}

export default ModalGallery
