import React from "react"
import { Link } from "gatsby"

import { formatPrice } from "../../../utils/format"
import {
  getBathroomsWord,
  getIcon,
  getRoomsWord,
  getSiteWord,
} from "../../../utils"
import { Icon } from "../../icon"

//  import { InterestingHouseForm } from "../../form"

import Img from "gatsby-image/index"

import styles from "./house-card.module.scss"
import "./house-card.scss"
import { daysFrom } from "../../../utils/date"

const HouseCard = ({ house, imgClass }) => {
  const title = `Будинок ${house.area} м2, ${house.city.name}`
  const thumbnail = house.thumbnail && house.thumbnail.fluid
  const hasIcon = house.hotSale || house.archived
  const houseStatus = house.status && house.status.name

  const created = house.createdAt
  const daysFromCreated = daysFrom(created)
  const isNew = daysFromCreated <= 14 && !house.archived

  return (
    <div className={styles.cardWrapper}>
      <div className={styles.card}>
        <Link to={`/house/${house.slug}`}>
          <div className={styles.thumbnailWrapper} title={house.snippet}>
            <Img
              fluid={thumbnail}
              objectFit="cover"
              objectPosition="50% 50%"
              className={[styles.thumbnail, imgClass].filter(c => c).join(" ")}
              alt={title}
            />
            <span className="house-code-img">{house.code}</span>
            <div className="hot-sale-icon-wrapper">
              {hasIcon ? (
                <Icon
                  name={house.archived ? "архів, продано" : "топ будинок"}
                  icon={getIcon(
                    house.archived ? "assigment turned in" : "fire"
                  )}
                />
              ) : null}
              {house.building && !house.archived ? (
                <Icon name="будується" icon={getIcon("builder")} />
              ) : null}
              {isNew ? (
                <Icon
                  name={`добавлено ${daysFromCreated} днів тому`}
                  icon={getIcon("new")}
                />
              ) : null}
            </div>
          </div>
          <div className={styles.footerInfo}>
            <h3>{house.city.name}</h3>
            {houseStatus ? <span>{houseStatus}</span> : <span>&nbsp;</span>}
            {/*<div>*/}
            {/*  <InterestingHouseForm title={title} thumbnail={thumbnail} />*/}
            {/*</div>*/}
          </div>
          <div className={styles.description}>
            <div className={styles.descriptionItem}>
              <b>{house.area}</b> м<sup>2</sup>
            </div>
            <div className={styles.descriptionItem}>
              <b>{house.bathrooms_number}</b>{" "}
              {getBathroomsWord(house.bathrooms_number)}
            </div>
            <div className={styles.descriptionItem}>
              <b>{house.roomsCount}</b> {getRoomsWord(house.roomsCount)}
            </div>
            <div className={styles.descriptionItem}>
              <b>{house.landArea}</b> {getSiteWord(house.landArea)}
            </div>
          </div>
          {!house.archived && (
            <div className="house-card-price">
              <span>Ціна:</span>
              <b>
                {house.price
                  ? `$${formatPrice(house.price || "")}`
                  : "не вказана"}
              </b>
            </div>
          )}
        </Link>
      </div>
    </div>
  )
}

export default HouseCard
