import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image/index"
import { formatPrice } from "../../../utils/format"

import styles from "./city-houses-swiper-card.module.scss"
import { Icon } from "../../icon"
import { getIcon } from "../../../utils"
const CityHousesSwiperCard = ({ city }) => {
  const title = `Будинки ${city.name}, ${city.count}`

  return (
    <div className={styles.cardWrapper}>
      <Link to={`/${city.slug}`} title={title}>
        <div className={styles.card}>
          <div className={styles.cover}></div>

          {city.thumbnail ? (
            <Img
              fluid={city.thumbnail.fluid}
              objectFit="cover"
              objectPosition="50% 50%"
              className={styles.thumbnail}
              alt={title}
            />
          ) : null}
          <div className={styles.bottomInfo}>
            <div className={styles.city}>{city.name}</div>
            <div className={styles.price}>
              {city.minPrice
                ? city.minPrice !== Infinity &&
                  `від ${formatPrice(city.minPrice)} $`
                : "все продано"}
            </div>
            {city.minArea ? (
              <div>
                {city.minArea !== city.maxArea ? (
                  <>
                    {city.minArea} - {city.maxArea} м<sup>2</sup>
                  </>
                ) : (
                  <>
                    {city.minArea} м<sup>2</sup>
                  </>
                )}
              </div>
            ) : null}
          </div>
          <div className={styles.houseCount}>
            {" "}
            <Icon name="кількість будинків" icon={getIcon("house")} />
            {city.count}
          </div>
        </div>
      </Link>
    </div>
  )
}

export default CityHousesSwiperCard
